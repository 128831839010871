// config.js

// https://apps3.colmayor.edu.co
// https://localhost:3005

const config = {
 baseURL: "https://apps3.colmayor.edu.co",

//baseURL: "http://localhost:3005",
};

export default config;

