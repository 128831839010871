import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import moment from 'moment';
import Header from './Header';
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config

const Reportes = () => {
  const [startWeek, setStartWeek] = useState('1');
  const [endWeek, setEndWeek] = useState('1');
  const [semestre, setSemestre] = useState('2024-2');
  const [horarios, setHorarios] = useState([]);
  const [prestamoshistorico, setPrestamosHistorico] = useState([]);
  const [docentes, setDocentes] = useState([]);  

  const fetchHorarios = async () => {
    try {
      const response = await fetch(`${config.baseURL}/api/v1/horario`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      const data = await response.json();
      setHorarios(data.data);
    } catch (error) {
      console.error('Error fetching horarios:', error);
    }
  };

  const fetchPrestamos = async () => {
    try {
      const response = await fetch(`${config.baseURL}/api/v1/prestamohistorico`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      const data = await response.json();
      setPrestamosHistorico(data);
    } catch (error) {
      console.error('Error fetching prestamos:', error);
    }
  };

  const fetchDocentes = async () => {
    try {
      const response = await fetch(`${config.baseURL}/api/v1/docente`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
        
      );  // Nueva ruta para obtener los docentes
      const data = await response.json();
      setDocentes(data.data);

    } catch (error) {
      console.error('Error fetching docentes:', error);
    }
  };

  useEffect(() => {
    fetchHorarios();
    fetchPrestamos();
    fetchDocentes();  // Llamar la función para obtener los docentes
  }, []);

  const getWeekNumber = (date) => {
    const startOfSemester = moment('2024-08-05');
    const currentDate = moment(date, "YYYY/MM/DD");
    return currentDate.diff(startOfSemester, 'weeks') + 1;
  };

  const extractWeekNumber = (weekString) => {
    console.log('String que llega', weekString);
    const match = weekString.match(/Semana\s+(\d+)/);
    console.log('String que hace match', match);
    return match ? parseInt(match[1], 10) : parseInt(weekString, 10);
};

// Función para calcular la asistencia
const calculateAttendance = (isPresencialidad) => {
  let report = [];

  const startWeekInt = extractWeekNumber(startWeek);
  const endWeekInt = extractWeekNumber(endWeek);
  if (!prestamoshistorico) {
    console.error('prestamoshistorico undefined or null');
    return report;
  }

  for (let i = startWeekInt; i <= endWeekInt; i++) {
    const weekData = prestamoshistorico.filter((prestamo) => {
      if (!prestamo || !prestamo.fecha) {
        return false;
      }
      return getWeekNumber(prestamo.fecha) === i;
    });

    const docentesFaltas = docentes.map(docente => {
      const id = docente.id;
      const nombre = docente.nombre;
      const cedula = docente.cedula;
      const facultad = docente.facultad;
      const cantidadDebiaVenir = horarios.filter(horario => horario.docenteId === id && horario.semestre === semestre).length;
      const cantidadVino = weekData.filter(prestamo => prestamo.docenteId === id).length;
      return {
        id,
        nombre,
        cedula,
        facultad,
        cantidadDebiaVenir,
        cantidadVino
      };
    });

    if (isPresencialidad) {
      const presentes = docentesFaltas.filter(docente => docente.cantidadVino > 0);
      presentes.forEach(docente => {
        let porcentajePresencialidad = '';
        if (docente.cantidadDebiaVenir > 0) {
          porcentajePresencialidad = `${Math.round((docente.cantidadVino / docente.cantidadDebiaVenir) * 100)}%`;
        } else if (docente.cantidadVino > 0) {
          porcentajePresencialidad = `Asistió a la institución ${docente.cantidadVino} veces`;
        }
        report.push({
          semana: i,
          docente: docente.nombre,
          cedula: docente.cedula,
          facultad: docente.facultad,
          debeVenir: docente.cantidadDebiaVenir,
          vino: docente.cantidadVino,
          porcentaje: porcentajePresencialidad
        });
      });
    } else {
      const ausentes = docentesFaltas.filter(docente => docente.cantidadVino === 0);
      ausentes.forEach(docente => {
        let porcentajeAusencia = '';
        if (docente.cantidadDebiaVenir > 0) {
          porcentajeAusencia = `${Math.round((docente.cantidadVino / docente.cantidadDebiaVenir) * 100)}%`;
        }
        report.push({
          semana: i,
          docente: docente.nombre,
          cedula: docente.cedula,
          facultad: docente.facultad,
          debeVenir: docente.cantidadDebiaVenir,
          vino: docente.cantidadVino,
          porcentaje: porcentajeAusencia
        });
      });
    }
  }
  return report;
};

// Función para obtener la fecha actual en formato dd/mm/aa
const getFormattedDate = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  return `${day}/${month}/${year}`;
};

// Función para crear y descargar el PDF
const generatePDF = (report, filename) => {
  const doc = new jsPDF();
  const img = new Image();
  img.src = "/logo.png";  // Asegúrate de tener la imagen en tu proyecto
  img.onload = () => {
    doc.addImage(img, 'PNG', 14, 5, 60, 50);  // Ajusta las posiciones y tamaños según sea necesario
    doc.text('Colegio Mayor de Antioquia', 14, 50);
    doc.text('Reporte de Asistencia', 14, 60);

    doc.autoTable({
      head: [['Semana', 'Docente', 'Cédula','Facultad', 'Debe Venir', 'Asistió', 'Porcentaje de Asistencia']],
      body: report.map(row => [row.semana, row.docente, row.cedula,row.facultad, row.debeVenir, row.vino, row.porcentaje]),
      startY: 65, // Ajustado para no solapar el texto
      headStyles: {
        fillColor: [10, 106, 80] // Color RGB para #0A6A50
      },
    });

    doc.save(filename);
  };
};

const handleGeneratePresencialidad = () => {
  const presenceReport = calculateAttendance(true);
  const formattedDate = getFormattedDate();
  const filename = `Informe_presencialidad_fecha_${formattedDate}.pdf`;
  generatePDF(presenceReport, filename);
};

const handleGenerateAusentismo = () => {
  const absenceReport = calculateAttendance(false);
  const formattedDate = getFormattedDate();
  const filename = `Informe_ausentismo_fecha_${formattedDate}.pdf`;
  generatePDF(absenceReport, filename);
};

  const weeks = Array.from({ length: 18 }, (_, i) => {
    const startOfSemester = moment('2024-08-05');
    const startOfWeek = startOfSemester.add(i, 'weeks');
    const endOfWeek = moment(startOfWeek).add(6, 'days');
    return `Semana ${i + 1} ${startOfWeek.format('DD/MM/YYYY')} a ${endOfWeek.format('DD/MM/YYYY')}`;
});


  const semestres = ["2024-2"];

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
          <Header />

      <div className="flex flex-col-1 flex-grow mx-2 my-20 sm:my-auto">
        <div className="mt-4 mb-8 flex flex-col flex-grow max-w-xl mx-auto bg-white rounded-lg shadow-md p-4 overflow-hidden" style={{ maxHeight: '40vh', height: 'auto' }}>
          <h1 className="text-[#0A6A50] mb-2 text-3xl font-semibold text-center">Reporte de Presencialidad</h1>
          <div className="flex flex-col flex-grow h-0 p-4 overflow-auto">
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="startWeek">
                  Semana de Inicio
                </label>
                <select
                  id="startWeek"
                  name="startWeek"
                  className="w-full py-2 px-3 text-gray-800 border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={startWeek}
                  onChange={(e) => setStartWeek(e.target.value)}
                  required
                >
                  {weeks.map((week, index) => (
                    <option
                
                    key={index} value={week}>
                      {week}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-1/2">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="endWeek">
                  Semana de Fin
                </label>
                <select
                  id="endWeek"
                  name="endWeek"
                  className="w-full py-2 px-3 text-gray-800 border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={endWeek}
                  onChange={(e) => setEndWeek(e.target.value)}
                  required
                >
                  {weeks.map((week, index) => (
                    <option 
                 
                    key={index} value={week}>
                      {week}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-1/2">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="semestre">
                  Semestre
                </label>
                <select
                  id="semestre"
                  name="semestre"
                  className="w-full py-2 px-3 text-gray-800 border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                  value={semestre}
                  onChange={(e) => setSemestre(e.target.value)}
                  required
                >
                  {semestres.map((semestre, index) => (
                    <option key={index} value={semestre}>
                      {semestre}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <button
                onClick={handleGeneratePresencialidad}
                className="w-full py-3 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70] ml-2"
              >
                Presencialidad
              </button>
              <button
                onClick={handleGenerateAusentismo}
                className="w-full py-3 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70] ml-2"
              >
                Ausentismo
              </button>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Reportes;
