import React from "react";
import axios from "axios";
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config
import { format } from "date-fns";
import { es } from "date-fns/locale";

const PrestamoInfo = ({ formData, onPrestamoReturned }) => {
  const token = localStorage.getItem("token");
  if (!token) throw new Error("Token not found");
  const { id, horainicio, horafin, Docente, Personal, Aula, observaciones, ArticuloPrestamo, llave, fecha } = formData;

  const handleDevolverTodo = async () => {
    try {
      // Actualizar disponibilidad de artículos
      const updateArticles = ArticuloPrestamo.map(async (articuloPrestamo) => {
        await axios.put(`${config.baseURL}/api/v1/articulo/${articuloPrestamo.articuloId}`, {
          disponible: true,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
      });

      // Verificar si `Aula` tiene un `id` válido antes de proceder
      if (Aula && Aula.id) {
        await axios.put(`${config.baseURL}/api/v1/aula/${Aula.id}`, {
          disponible: true,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
      } else {
        throw new Error("El objeto Aula no tiene un id válido.");
      }

      await Promise.all(updateArticles);

      // Eliminar registros de préstamos
      await axios.delete(`${config.baseURL}/api/v1/articuloprestamocompleto/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      await axios.delete(`${config.baseURL}/api/v1/prestamo/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      onPrestamoReturned(id);
    } catch (error) {
      console.error("Error al devolver el préstamo:", error);
    }
  };

  const handleDeleteArticulo = async (prestamoId, articuloId) => {
    try {
      await axios.put(`${config.baseURL}/api/v1/articulo/${articuloId}`, {
        disponible: true,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      await axios.delete(`${config.baseURL}/api/v1/articuloprestamo/${prestamoId}/${articuloId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      const updatedArticuloPrestamo = ArticuloPrestamo.filter(
        (articuloPrestamo) => articuloPrestamo.articuloId !== articuloId
      );

      const updatedPrestamo = { ...formData, ArticuloPrestamo: updatedArticuloPrestamo };

      onPrestamoReturned(updatedPrestamo);
    } catch (error) {
      console.error("Error al eliminar el artículo del préstamo:", error);
    }
  };

  const handleDeleteLlave = async () => {
    try {
      await axios.put(`${config.baseURL}/api/v1/prestamo/${id}`, {
        llave: "",
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      onPrestamoReturned({ ...formData, llave: "" });
    } catch (error) {
      console.error("Error al eliminar la llave:", error);
    }
  };

  const usuarioNombre = Docente ? Docente.nombre : (Personal ? Personal.nombre : "No especificado");
  const usuarioCedula = Docente ? Docente.cedula : (Personal ? Personal.cedula : "No especificado");
  const usuariodependencia = Docente ? Docente.facultad : (Personal ? Personal.dependencia : "No especificado")

  // Función para formatear la fecha
  const formatDate = (dateString) => {
    if (!dateString) return "Fecha no especificada";
    const date = new Date(dateString);
    return format(date, "EEEE d 'de' MMMM 'del' yyyy HH:mm", { locale: es });
  };

  
  const handleDeleteObservaciones = async () => {
    try {
      await axios.put(`${config.baseURL}/api/v1/prestamo/${id}`, {
        observaciones: "",
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      onPrestamoReturned({ ...formData, observaciones: "" });
    } catch (error) {
      console.error("Error al eliminar las observaciones:", error);
    }
  };
  return (
    <div className="p-12 px-6 py-10 sm:px-10 sm:py-6 bg-white rounded-lg shadow-md lg:shadow-lg mt-2 mx-2">
      <h2 className="text-base text-[#0A6A50] font-bold">Préstamo generado:</h2>
      <div className="grid grid-cols-1 justify-between mt-2 text-sm">
        <p>
          <span className="font-semibold">Salón:</span> {Aula ? Aula.nombreAula : "Aula no especificada"}
        </p>
        <p>
          <span className="font-semibold">Usuario:</span> {usuarioNombre}
        </p>
        <p>
          <span className="font-semibold">Cédula:</span> {usuarioCedula}
        </p>
        <p>
          <span className="font-semibold">Dependencia:</span> {usuariodependencia}
        </p>
    
        <p>
          <span className="font-semibold">Fecha:</span> {formatDate(fecha)}
        </p>
        {horainicio && (
          <p>
            <span className="font-semibold">Inicio:</span> {horainicio}
          </p>
        )}
        {horafin && (
          <p>
            <span className="font-semibold">Fin:</span> {horafin}
          </p>
        )}
      </div>
      {observaciones && (
        <div className="flex justify-between items-center mt-2">
          <p className="text-sm">{observaciones}</p>
          <button
            type="button"
            className="ml-2 text-red-600 hover:text-red-800"
            onClick={handleDeleteObservaciones}
          >
            🗑️
          </button>
        </div>
      )}
      {(llave || (ArticuloPrestamo && ArticuloPrestamo.length > 0)) && (
        <div className="mt-4">
          <h3 className="text-sm font-semibold">Elementos prestados:</h3>
          <ul className="list-disc list-inside mt-2 text-sm">
            {llave && (
              <li className="flex justify-between items-center">
                <p>{llave}</p>
                <button
                  type="button"
                  className="ml-2 text-red-600 hover:text-red-800"
                  onClick={handleDeleteLlave}
                >
                  🗑️
                </button>
              </li>
            )}
            {ArticuloPrestamo && ArticuloPrestamo.length > 0 && (
              ArticuloPrestamo.map((articuloPrestamo, index) => (
                <li key={index} className="flex justify-between items-center">
                  {articuloPrestamo.Articulo.nombreArticulo}
                  <button
                    type="button"
                    className="ml-2 text-red-600 hover:text-red-800"
                    onClick={() => handleDeleteArticulo(id, articuloPrestamo.articuloId)}
                  >
                    🗑️
                  </button>
                </li>
              ))
            )}
          </ul>
        </div>
      )}
      <button
        type="button"
        className="w-full mt-2 py-3 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70]"
        onClick={handleDevolverTodo}
      >
        Devolver todo
      </button>
    </div>
  );
};

export default PrestamoInfo;
